import { http } from '@/http/axios.js'
import { http_limit } from '@/http/axios_limit.js'
import axios from 'axios'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { showScreenLoading, hideScreenLoading } from '@/http/loading.js'
import { dateFormat } from '@/utils/index.js'
import { Message } from 'element-ui'
import { router } from '@/router'
// 单个
export function getHitListSingleAPI(data) {
  return http({
    url: '/admin/roster/single/hit',
    method: 'post',
    data
  })
}
// 批量撞库
export function getHitListImportAPI(data) {
  return http({
    url: '/admin/roster/import/hit',
    method: 'post',
    data
  })
}
// 上传
export function handleUploadAPI(data) {
  return http_limit({
    url: '/admin/roster/import/hit',
    method: 'post',
    data
  })
}
export function downloadFile(pdfUrl, name) {
  axios({
    url: pdfUrl,
    method: 'GET',
    responseType: 'blob' // 必须指定为blob类型才能下载
  }).then(res => {
    const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', name)
    document.body.appendChild(link)
    link.click()
  })
}
export function DownloadAPI(data) {
  showScreenLoading()
  axios({
    method: 'post',
    url: '/api/admin/roster/import/hit',
    responseType: 'blob',
    data,
    headers: {
      Authorization: getToken() || ''
    }
  })
    .then(async res => {
      hideScreenLoading()
      let data = res.data
      // console.log('@@@@', res.data)
      if (!data || data.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        let response = await new Response(data).text()
        response = JSON.parse(response)
        Message.error(response?.message || '导出失败')
        removeToken()
        router.push('/login')
        return
      }

      let url = window.URL.createObjectURL(new Blob([data]))
      let a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      let filename = `撞库查询-${dateFormat()}.xlsx`
      a.setAttribute('download', decodeURIComponent(filename))
      document.body.appendChild(a)
      a.click() //执行下载
      window.URL.revokeObjectURL(a.href) //释放url
      document.body.removeChild(a) //释放标签
    })
    .catch(error => {
      hideScreenLoading()
    })
}
